import { graphql, Link } from 'gatsby';
import * as React from 'react';
import { useState } from 'react';

import Collage from '../components/collage';
import DisplayToggles from '../components/displayToggles';
import Gallery from '../components/gallery';
import Layout from '../components/layout';
import { Seo } from '../components/seo';
import joinClassNames from '../utilities/joinClassNames';

const VisitPage = ({ data }) => {
  const [layout, setLayout] = useState('list');

  const [collageHovered, setCollageHovered] = useState(false);
  const [galleryHovered, setGalleryHovered] = useState(false);
  const [listHovered, setListHovered] = useState(false);

  // Copied from the component here and below under List view
  const [highlightColor, setHighlightColor] = useState('');

  return (
    <Layout
      navbarTheme={
        listHovered || collageHovered || galleryHovered
          ? 'grayLight'
          : 'grayDark'
      }
    >
      <main className="relative min-h-screen min-w-screen py-16 px-8 flex flex-col items-center justify-center">
        {layout === 'collage' ? (
          <Collage
            entries={data.allContentfulVisit.nodes}
            hovered={collageHovered}
            setHovered={setCollageHovered}
          />
        ) : layout === 'gallery' ? (
          <Gallery
            entries={data.allContentfulVisit.nodes}
            hovered={galleryHovered}
            setHovered={setGalleryHovered}
          />
        ) : (
          <>
            <div
              className={joinClassNames(
                listHovered ? 'opacity-100' : 'opacity-0',
                `bg-${highlightColor}`,
                'absolute inset-0 z-0 transition-opacity pointer-events-none'
              )}
            />

            <div
              className={joinClassNames(
                listHovered &&
                  (highlightColor === 'black' || highlightColor === 'gray')
                  ? 'text-white'
                  : 'text-black',
                'container mx-auto text-xl md:text-3xl lg:text-5xl leading-snug z-10'
              )}
            >
              {data.allContentfulVisit.nodes.map((entry, index) => (
                <React.Fragment key={index}>
                  <Link
                    className={`font-bold hover:font-light cursor-${highlightColor}`}
                    to={entry.slug}
                    onMouseEnter={() => {
                      setHighlightColor(entry.highlightColor.toLowerCase());
                      if (entry.highlightColor !== 'White') {
                        setListHovered(true);
                      }
                    }}
                    onMouseLeave={() => setListHovered(false)}
                  >
                    {entry.title}
                  </Link>
                  {index !== data.allContentfulVisit.nodes.length - 1 && (
                    <>
                      {` `}
                      <span className="inline-block mx-4">/</span>
                      {` `}
                    </>
                  )}
                </React.Fragment>
              ))}
            </div>
          </>
        )}
      </main>

      <DisplayToggles
        layout={layout}
        setLayout={setLayout}
        listHovered={listHovered}
      />
    </Layout>
  );
};

export default VisitPage;

export const Head = ({ data }) => (
  <Seo
    title={data.contentfulGenericPage.meta.title}
    description={data.contentfulGenericPage.meta.description}
  />
);

export const query = graphql`
  {
    contentfulGenericPage(contentful_id: { eq: "3o0DtY2AuoZah9Kc5AQdH4" }) {
      meta {
        title
        description
      }
    }
    allContentfulVisit(sort: { order: DESC, fields: updatedAt }) {
      nodes {
        title
        slug
        highlightColor
        featuredImage {
          gatsbyImage(width: 640)
          publicUrl
        }
      }
    }
  }
`;
